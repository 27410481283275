import coming from "./coming-soon.svg";
import "./App.css";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-221466340-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="App">
      {/* <img src={logo} className="App-logo" alt="logo spin" /> */}
      <div className="container">
        <img src={coming} className="App-coming" alt="logo" />
      </div>
    </div>
  );
}

export default App;
